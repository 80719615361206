type UTMParams = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
};

type TrackingData = {
  utmParams: UTMParams;
  referrer: string;
  timestamp: string;
  url: string;
};

const TRACKING_KEY = "UTM_PARAMS"

const parseQuery = (queryString: string): UTMParams => {
  return Object.fromEntries(new URLSearchParams(queryString)) as UTMParams;
};

export const trackUTMAndReferrer = (): void => {
  if (typeof window === "undefined") return;

  const utmParams: (keyof UTMParams)[] = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];
  const currentParams = parseQuery(window.location.search);
  const storedData: TrackingData = JSON.parse(
    localStorage.getItem(TRACKING_KEY) || "{}"
  );

  const newUTMData = utmParams.reduce<UTMParams>((acc, param) => {
    if (currentParams[param]) {
      acc[param] = currentParams[param];
    }
    return acc;
  }, {});

  const referrer = document.referrer || storedData.referrer || "direct";

  if (Object.keys(newUTMData).length > 0 || !storedData.timestamp) {
    const trackingData: TrackingData = {
      utmParams: { ...storedData.utmParams, ...newUTMData },
      referrer: referrer,
      timestamp: new Date().toISOString(),
      url: window.location.href,
    };
    localStorage.setItem(TRACKING_KEY, JSON.stringify(trackingData));
  }
};

export const getStoredTrackingData = (): TrackingData | null => {
  if (typeof window === "undefined") return null;
  const data = localStorage.getItem(TRACKING_KEY);
  return data ? JSON.parse(data) : null;
};

export const clearStoredTrackingData = (): void => {
  if (typeof window === "undefined") return;
  localStorage.removeItem(TRACKING_KEY);
};
