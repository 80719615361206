"use client";

import React, { useEffect } from "react";
import { trackUTMAndReferrer } from "../utils/utm";

interface TrackingProviderProps {
  children: React.ReactNode;
}

export function TrackingProvider({
  children,
}: TrackingProviderProps): React.ReactElement {
  useEffect(() => {
    trackUTMAndReferrer();
  }, []);

  return <>{children}</>;
}
